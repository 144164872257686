import { promotionCenter } from 'public/src/pages/common/promotion'

const setPretreatInfo = ({ productItem = null, key = null, value = null } = {}) => {
  if (!productItem || !key) return
  if (!productItem.pretreatInfo) {
    productItem.pretreatInfo = {}
  }
  productItem.pretreatInfo[key] = value
}

// set服务类标签 中的 促销标签
const setPromotionLabel = ({ productItem, mixPromotionInfo } = {}) => {
  const set = ({ serviceLabels }) => {
    const promotionLabel = mixPromotionInfo[0]?.lable
    const promotionLabelIndex = serviceLabels.findIndex(item => item.promotionLabel)
    if (promotionLabelIndex > -1) {
      if (promotionLabel) {
        serviceLabels[promotionLabelIndex].promotionLabel = promotionLabel
      } else {
        serviceLabels.splice(promotionLabelIndex, 1)
      }
    }
  }

  set({ serviceLabels: productItem?.pretreatInfo?.mobileVerticalView?.serviceLabels || [] }) // 双图服务类标签
  set({ serviceLabels: productItem?.pretreatInfo?.mobileBroadwiseView?.serviceLabels || [] }) // 单图服务类标签
}

// 促销 TODO: 全量切新商卡后，promotionReady可删除
const setPromotionInfo = ({ products = [], promotionInfoFromServer = {}, language = {} } = {}) => {
  promotionCenter.assignItemsSync({ 
    items: products, 
    isGetLable: true, 
    isAppPromotion: true, 
    promotionInfoFromServer,
    language
  })

  products.forEach(productItem => {
    const promotionInfo = productItem.promotionModule.rawModule || []
    const mixPromotionInfo = promotionInfo.filter(item => {
      if (item.typeId) {
        // ab价不展示服务类型标签
        if (+item.typeId === 32) {
          return false
        } else if (+item.typeId === 31) { // 31品类限定商家限时直降 wiki.pageId=1198803653
          return item.promotion_logo_type === 3
        } else if ([3, 10, 11, 29].includes(+item.typeId)) { // 3: 限时折扣 10: 闪购 11: 直播闪购 29: 促销
          return true
        } else if (item.lable) {
          return true
        }
      }
      return false
    }) || []

    setPromotionLabel({ productItem, mixPromotionInfo })
    setPretreatInfo({ productItem, key: 'mixPromotionInfo', value: mixPromotionInfo })
    setPretreatInfo({ productItem, key: 'promotionReady', value: true })
  })
}

// 分布式筛选 TODO: 全量切新商卡后，searchFiltersReady可删除
const dealSearchFilter = ({ productItem, searchFilterDependencies, sellingPointInSearchFilter, itemConfig }) => {
  const { picTopNav, cloudTags, filterWords, NoSellingPoint = false, SameFilter = false } = searchFilterDependencies || {}
  if ((!productItem?.attrs?.length && !productItem.pretreatInfo?.sellingPoints?.length) || !itemConfig?.showSearchFilters) {
    return setPretreatInfo({ productItem, key: 'searchFiltersReady', value: true })
  }

  let sellingPoints = []
  if (!NoSellingPoint && itemConfig?.showSellingPoint) {
    sellingPoints = productItem?.pretreatInfo?.sellingPoints || [] // 卖点
    // 字段统一格式化一下
    sellingPoints = sellingPoints
      // 过滤标签云和已点击筛选词重复的项
      .filter(sellingPoint => 
        !cloudTags?.find(cloudTag => cloudTag.tag_id == sellingPoint.tag_val_id) && 
        !filterWords?.find(word => word == sellingPoint.tag_val_name_lang)
      ) 
      .map(item => {
        const { tag_id, tag_val_id, tag_val_name_lang } = item
        item.id = `${tag_id}_${tag_val_id}` || ''
        item.filterId = tag_val_id || ''
        item.filterWord = tag_val_name_lang || ''
        item.dataSource = 1

        return item
      })
  }

  // 过滤分布式筛选中已经出现过的卖点
  if (SameFilter) {
    if (sellingPoints.length && sellingPointInSearchFilter.length) {
      sellingPoints = sellingPoints.filter(sp => !sellingPointInSearchFilter.find(spisf => spisf.id === sp.id))
    }
    sellingPointInSearchFilter = [...sellingPointInSearchFilter, ...sellingPoints]
  }
  
  let coreAttrs = productItem.attrs || [] // 核心属性
  const navs = picTopNav[0]?.navs || [] // 图文导航
  coreAttrs = coreAttrs
    // 过滤图文导航和已点击筛选词重复的项
    .filter(attr => 
      !navs.find(nav => nav.nav_id == `${attr.attr_id}_${attr.attr_value_id}`) && 
      !filterWords?.find(word => word == attr.attr_values)
    )
    .map(item => {
      // 字段统一格式化一下
      const { attr_id, attr_value_id, attr_values } = item
      item.id = `${attr_id}_${attr_value_id}`
      item.filterId = attr_value_id || ''
      item.filterWord = attr_values || ''
      item.dataSource = 2

      return item
    })

  setPretreatInfo({ productItem, key: 'searchFilters', value: sellingPoints.concat(coreAttrs) })
  setPretreatInfo({ productItem, key: 'searchFiltersReady', value: true })
}

// TODO: 待接入最近浏览 再加上
// price cut
// const dealPriceCut = ({ productItem }) => {
//   if (!productItem) {
//     setPretreatInfo({ productItem, key: 'priceCutReady', value: true })
//     return
//   }

//   const { expired_salePrice, salePrice } = productItem // expired_salePrice过期价格, salePrice当前价格

//   const salePriceDelta = Number(expired_salePrice?.usdAmount || 0) - Number(salePrice?.usdAmount || 0)

//   if (salePriceDelta > 0) {
//     setPretreatInfo({ productItem, key: 'priceCut', value: salePriceDelta })
//   }

//   setPretreatInfo({ productItem, key: 'priceCutReady', value: true })
// }

const setInfoByProductsLoop = ({ products, searchFilterDependencies, itemConfig }) => {
  const sellingPointInSearchFilter = []
  products.forEach(productItem => {
    dealSearchFilter({ productItem, searchFilterDependencies, sellingPointInSearchFilter, itemConfig })
    // dealPriceCut({ productItem })
  })
}


const dealProductsPretreatInfo = ({ products, itemConfig, promotionInfoFromServer, language, searchFilterDependencies }) => {
  // 融合商卡不走预处理
  if (products?.[0]?.bffGoodsInfo) {
    return products
  } else {
    setInfoByProductsLoop({ products, searchFilterDependencies, itemConfig })
    setPromotionInfo({ products, promotionInfoFromServer, language })

    return products
  }
}

export {
  dealProductsPretreatInfo,
}
