import LOCAL_CONFIGS from 'public/src/pages/cartNew/utils/json/behaviorLabel.json'
import { template } from '@shein/common-function'
const { USER_BEHAVIOR_CONFIGS } = gbCommonInfo

const BehaviorLabel = {
  /**
   * 获取标签配置项, 优先使用Apollo配置, 本地配置兜底
   * @returns {Array}
   */
  getConfigs: () => {
    if (Array.isArray(USER_BEHAVIOR_CONFIGS) && USER_BEHAVIOR_CONFIGS.length) {
      return USER_BEHAVIOR_CONFIGS
    }
    return LOCAL_CONFIGS
  },

  /**
   * 获取tsp请求的标签ID数组
   * @param {String} abtStrValue abt参数值: sales1,views
   * @returns {Array} ['3454', '3454']
   */
  getLabelIdsByStr: (abtStrValue) => {
    if (!abtStrValue || abtStrValue == 'Hide') return BehaviorLabel.getConfigs().map(v => v.labelId).flat()
    return BehaviorLabel.getConfigs().filter((v) => (abtStrValue).includes(v.labelText)).map(v => v.labelId).flat()
  },

  /**
   * 获取展示优先级最高的行为标签
   * @desc 标签包含： 普通标签、实时标签
   * @inheritdoc BehaviorLabel.getSortedLabels
   * @return {Object}
   */
  getPriorityOne: (abtStrValue, tspLabelObj = {}, item = {}) => {
    const arr = BehaviorLabel.getSortedLabels(abtStrValue, tspLabelObj, item)
    return arr[0]
  },

  /**
   * 获取展示优先级最高的普通行为标签
   * @desc 标签包含： 普通标签、实时标签
   * @inheritdoc BehaviorLabel.getSortedLabels
   * @returns {Object}
   */
  getPriorityOneForNormal: (abtStrValue, tspLabelObj = {}, item = {}, ctx = {}) => {
    const showBehaviorLabel = BehaviorLabel.getSortedLabels(abtStrValue, tspLabelObj, item, ctx)
    const [priorityOne] = BehaviorLabel.getNormalLabel(showBehaviorLabel)

    return priorityOne
  },

  /**
   * 获取排序后行为标签
   * 1.如果开启了abt，则还是拿abt的参数获取标签id去请求tsp, tsp命中，就展示
   * 2.如果关闭了abt，则拿所有标签id去请求tsp，有结果则记录埋点，不展示标签
   * @param {String} abtStrValue abt参数值: sales1,views
   * @param {Object} tspLabelObj {标签ID: 数量}
   * @param {Object} cartItem 商品条目信息
   * @param {Object} ctx 拓展字段
   * @returns {Array}
   */
  getSortedLabels: (abtStrValue, tspLabelObj = {}, item = {}, ctx = {}) => {
    const showLabelTag = !abtStrValue || abtStrValue == 'Hide' ? false : true // 是否展示标签
    const labelIds = BehaviorLabel.getLabelIdsByStr(abtStrValue)
    if (!labelIds?.length || !tspLabelObj) return []
    const filterConfs = BehaviorLabel.getLabelConfigByIds(labelIds).reduce((acc, config) => {
      const showLabel = BehaviorLabel.getShowLabel(config, tspLabelObj, item, ctx)
      if (showLabel) {
        acc.push({
          labelId: config.labelId,
          iconSrc: config.iconSrc,
          icon: config.icon,
          langKey: config.langKey,
          labelText: config.labelText,
          labelGear: config.labelGear || null,
          showLabel,
          showLabelTag,
        })
      }
      return acc
    }, [])
    // 优先档位顺序，再判断abt配置顺序
    filterConfs.sort((a, b) => {
      if (a.showLabel?.level != b.showLabel?.level && a.showLabel?.level != -1 && b.showLabel?.level != -1) {
        return b.showLabel.level - a.showLabel.level
      }
      // labelId 为数组时，取第一个元素来对比 ABT 配置顺序
      function getOne(labelId) {
        const [first] = Array.isArray(labelId) ? labelId : [labelId]
        return first
      }
      return labelIds.indexOf(getOne(a.labelId)) - labelIds.indexOf(getOne(b.labelId))
    })

    return filterConfs
  },

  /**
   * 根据数量，获取档位和展示文案
   * @desc 线上已有标签类型
   * @param {Number} num
   * @param {Array} rules
   * @returns {Object}
   */
  getLabelByNum: (num, rules) => {
    if (!rules?.length) return
    for (let i = 0; i < rules.length; i++) {
      const { start, end, gear, invalid, includeLowerLimit = false } = rules[i]
      // -1代表无限大, 标识最后一个档位的end值
      // includeLowerLimit是否包含下限
      let startCondition = includeLowerLimit ? start <= num : start < num
      if (startCondition && (end == -1 || end >= num) && !invalid) {
        const _num = end == -1 ? start : start + (Math.floor((num - start) / gear) * gear)
        const text = _num < 1000 ? `${_num}+` : `${_num / 1000}k+`
        return {
          level: i,
          text: [
            text,
          ]
        }
      }
    }
  },

  /**
   * 获取文案
   * @param {Object} label
   * @param {Object} language
   * @returns {String}
   */
  getText: (label, language) => {
    if (!label || !label.labelId || !label.showLabelTag) return ''
    const { langKey, showLabel } = label
    if(showLabel?.flag) {
      return template(showLabel?.flag, language['SHEIN_KEY_PWA_31778'])
    }
    return template(...showLabel?.text, language[langKey])
  },

  /**
   * 获取埋点上报文案
   * @param {Object} label
   * @param {Object} language
   * @returns {String}
   */
  getSaText: (label, language) => {
    if (!label || !label.labelId || !label.showLabelTag) return ''
    const { labelId } = label
    const txt = BehaviorLabel.getText(label, language)
    return `show_behavior_label_03_${labelId}_${txt?.split(' ')?.join('_')}`
  },

  /**
   * 根据标签 id 获取标签配置项
   * @param {Number} labelId 标签 ID
   * @returns {Object} 返回标签配置项
   */
  getLabelConfigById: (labelId) => {
    const config = BehaviorLabel.getConfigs()
    return config.find((item) => 
      Array.isArray(item.labelId)
      // 转成字符串对比
        ? item.labelId.includes(String(labelId))
        : item.labelId === String(labelId)
    )
  },

  /**
   * 获取多个标签的配置项
   * @param {Array} labelIds 
   * @returns {Array} 返回标签配置项
   */
  getLabelConfigByIds: (labelIds = []) => {
    return [...labelIds.reduce((acc, labelId) => {
      acc.add(BehaviorLabel.getLabelConfigById(labelId))
      return acc
    }, new Set())]
  },

  /**
   * 获取标签展示的信息
   * @param {Object} config 标签配置项
   * @param {Object} tspLabelObj tsp 请求的标签数据
   * @param {Object} item 商品条目信息
   * @param {Object} ctx 上下文
   * @returns {Object}
   */
  getShowLabel: (config, tspLabelObj, item, ctx = {}) => {
    // 低价标签
    if (config.labelText === 'lowestprice') {
      return BehaviorLabel.getShowLabelForLowerPrice(tspLabelObj, item, ctx)
    } else if (['buytime', 'commentime'].includes(config.labelText)) {
      // 购买时间/评论时间标签
      return BehaviorLabel.getShowLabelForTime(tspLabelObj[config.labelId])
    } else {
      // 线上已有的标签
      return BehaviorLabel.getLabelByNum(tspLabelObj[config.labelId], config.rules)
    }
  },

  /**
   * 获取支付时间/评论时间标签展示的信息
   * @param {String} tspLabel tsp 请求的标签数据
   * @returns {Object}
   */
  getShowLabelForTime: (tspLabel = '') => {
    if (!tspLabel) return
    const arr = (tspLabel).split(',').map((v) => {
      const idAndTime = v.split(':')
      return {
        memberId: idAndTime[0],
        time: idAndTime[1],
      }
    })
    arr.sort((a, b) => b.time - a.time)
    
    const { memberId, time } = arr[0]
    if (!memberId || !time) return
    let t = time?.toString()?.length == '13' ? time : time * 1000
    const second = Math.floor((Date.now() - t) / 1000)
    const formatTime = (num) => num < 60 ? `${num}s` : num >= 60 && num < 3600 ? `${Math.floor(num / 60)}m` : `${Math.floor(num / 3600)}h`
    if (second >= 0 && second < 60 * 60 * 24) {
      return {
        level: -1,
        memberId,
        text: [
          formatTime(second)
        ],
      }
    }
  },

  /**
   * 获取低价标签展示的信息
   * @param {Object} tspLabelObj  tsp 标签数据
   * @param {Object} item 商品信息
   * @param {Object} ctx 上下文
   * @returns {Object}
   */
  getShowLabelForLowerPrice: (tspLabelObj, item, ctx = {}) => {
    // @desc 
    // 取商品当前价格为 y， 如果有特价，则取特价、如果没有特价，则取原价；
    // 当商品命中新人闪购、特殊闪购、付费会员、S3会员，券后价，默认取原价为 y；
    const toArr = arr => Array.isArray(arr) ? arr : [arr]
    const promotionInfo = toArr(item?.product?.product_promotion_info || item?.promotionInfo || {}) 
    const isHit = type => promotionInfo.find(item => item.typeId == type || item.type_id == type)
    const isVip = isHit('29') // 付费会员
    const isS3 = isHit('12') // S3 会员
    const isCoupon = isHit('32') // 券后价
    const flashPromotion = isHit('10')
    const isFlash = flashPromotion && [2, 3].includes(item.flashType || flashPromotion.flashType || flashPromotion.flash_type ) // 闪购

    // @desc
    // 取值逻辑
    // 购物车： 原价 product?.retailPrice，销售价 unitPrice 
    // 其他地方: 原价 retail_price 或 retailPrice，销售价 sale_price 或 salePrice
    const amount = isCoupon || isFlash || isVip || isS3
      ? (item.product?.feOriginalPrice?.amount || item.retail_price?.amount || item.retailPrice?.amount)
      : (item.unit_price_before_coupon?.amount || item.sale_price?.amount || item.salePrice?.amount) 
    const LowestPriceDays = ctx?.LowestPriceDays
    if(LowestPriceDays) {
      if (amount <= +tspLabelObj['3866'] && amount <= +tspLabelObj['4017'] && tspLabelObj['4018'] > 1) { // 90天最低价。
        return {
          level: -1, // priority 
          flag: 90,
          text: []
        }
      } else if (amount <= +tspLabelObj['3865'] && amount <= +tspLabelObj['4016'] && tspLabelObj['4018'] > 1) { // 60天最低价。
        return {
          level: -1, // priority 
          flag: 60,
          text: []
        }
      } else if (amount <= +tspLabelObj['3864'] && amount <= +tspLabelObj['4015'] && tspLabelObj['4018'] > 1) { // 30天最低价。
        return {
          level: -1, // priority 
          flag: 30,
          text: []
        }
      }
    }
    if (amount <= +tspLabelObj['3864'] && amount <= +tspLabelObj['4015'] && tspLabelObj['4018'] > 1) {
      return {
        level: -1,
        text: []
      }
    }
  },

  /**
   * 获取常规的行为标签
   * @desc 过滤掉 buytime、commentime 两种类型
   * @param {Array|Object} showBehaviorLabel 展示的行为标签集合
   * @returns {Array|Object}
   */
  getNormalLabel: (showBehaviorLabel = []) => {
    const isRealTimeLabel = (labelText) => ['buytime', 'commentime'].includes(labelText)
    if (Array.isArray(showBehaviorLabel)) {
      return showBehaviorLabel.filter(item => !isRealTimeLabel(item.labelText))
    } else if (!isRealTimeLabel(showBehaviorLabel.labelText)) {
      return showBehaviorLabel
    }
  }
}

export default BehaviorLabel
